import BaseBlockContent from "@sanity/block-content-to-react";
import React from "react";
import Figure from "./figure";
import { Link } from "gatsby";
const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>;
        case "h2":
          return <h2>{props.children}</h2>;
        case "h3":
          return <h1>{props.children}</h1>;
        case "h4":
          return <h4>{props.children}</h4>;
        default:
          return <p>{props.children}</p>;
      }
    },
    figure(props) {
      return <Figure image={props.node} />;
    },
  },
  marks: {
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${slug.current}`;
      return <Link to={href}>{children}</Link>;
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
);
export default BlockContent;
