import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { menuHandler } from "../sideMenu/menuHandler";
import { itemClick } from "../sideMenu/assets/itemClick";
import { Link } from "gatsby";

const NewHeader = ({ props }) => {
  useEffect(() => {
    menuHandler();
  }, []);

  const data = useStaticQuery(graphql`
    {
      allSanityNavigationMenu {
        edges {
          node {
            ...NavMenu
            parentPage {
              slug {
                current
                _key
              }
              _key
            }
            _key
          }
        }
      }
    }
  `);

  //Setting custom ID on every menu item.
  {
    var counter = 0;
  }
  const getId = (title) => {
    counter++;
    var pos = title.indexOf(" ");
    if (pos != -1) {
      var s = title.substring(0, pos);
      return "menuId" + s + counter;
    }
    return "menuId" + title + counter;
  };

  // Checks if a page is the parent page to handle correct URLS
  const isParentPage = (currentLink) => {
    if (props.slug.current === currentLink) {
      return `/${currentLink}`;
    } else {
      return `/${props.slug.current}/${currentLink}`;
    }
  };

  // Checks if the array loop items have the correct parent pages before looping them out
  const ParentChecker = ({ children, node }) => {
    if (node?.node?.parentPage?.slug?.current === props.slug.current) {
      return children;
    } else {
      return null;
    }
  };
  const LevelTwo = ({ item2 }) => {
    const item2Mapper = item2.map((link2) => {
      return (
        <li
          className="mobilHeader container-fluid p-0 menuLevel2 menuItem menuitemHidden"
          id={getId(link2.title)}
        >
          <Link
            to={isParentPage(link2.landingPageRoute.slug.current)}
            className="linkBox"
            activeClassName="active"
            partiallyActive={true}
          >
            {link2.title}
          </Link>
          {link2.subLink.length > 0 && (
            <ul>
              <LevelTwo item2={link2.subLink} />
            </ul>
          )}
        </li>
      );
    });
    return item2Mapper;
  };

  const LevelOne = () => {
    const mapper = data.allSanityNavigationMenu.edges.map((item1) => (
      <ParentChecker node={item1}>
        {item1?.node.items.map((node2) => {
          return (
            <li
              className="mobilHeader menuLevel1 menuItem menuitemHidden container-fluid p-0"
              id={getId(node2.title)}
            >
              <Link
                to={isParentPage(node2.landingPageRoute.slug.current)}
                className="linkBox"
                activeClassName="active"
                partiallyActive={true}
              >
                {node2.title}
              </Link>
              {node2.subLink.length > 0 && (
                <ul className="menuList">
                  <LevelTwo item2={node2.subLink} />
                </ul>
              )}
            </li>
          );
        })}
      </ParentChecker>
    ));
    return mapper;
  };

  return (
    <ul className="d-md-none">
      <LevelOne />
    </ul>
  );
};

export default NewHeader;
