const sanityClient = require("@sanity/client");
const client = sanityClient({
  projectId: process.env.SANITY_PROJECT_ID || "gowx3bv0",
  dataset: process.env.SANITY_DATASET || "production",
  useCdn: false, // `false` if you want to ensure fresh data
  watchMode: false,
  // overlayDrafts: true,
});

export default client;
